import { API_URL } from 'configuration/urls';
import { _auth, _firestore } from '../firebase';
import { ORDER_PAYMENT_TYPE } from './enums';

export async function fetchDineinTableAndOrder({
  floorLayoutId,
  dineInObjectId,
  storeId,
}) {
  let dineInObjectStatus = {};
  let dineInOrder = null;

  const DINE_IN_OBJECT_STATUSES_API = `${API_URL}stores/v1/dineInObjectStatuses?filter.storeId=${storeId}&filter.layoutId=${floorLayoutId}&filter.dineInObjectId=${dineInObjectId}`;

  const accessToken = await _auth.currentUser.getIdToken(true);

  const response = await fetch(DINE_IN_OBJECT_STATUSES_API, {
    method: 'GET',
    mode: 'no-cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  const { data: { dineInObjectStatuses = [] } = {} } = await response.json();
  dineInObjectStatus = dineInObjectStatuses[0];

  // check if the table is already occupied and has an order placed already
  const orderId = dineInObjectStatus?.currentOrder;
  if (dineInObjectStatus?.isOccupied && orderId?.length > 0) {
    const order = await _firestore.collection('Orders').doc(orderId).get();
    dineInOrder = order.data();
    if (dineInOrder) dineInOrder._id = orderId;
  }

  return {
    dineInObjectStatus,
    dineInOrder,
  };
}

export async function bookDineinTable({
  floorLayoutId,
  dineInObjectId,
  storeId,
}) {
  const BOOK_DINE_IN_TABLE_API = `${API_URL}stores/v1/dineInOccupancies`;
  const body = {
    dineInObjectIds: [dineInObjectId],
    layoutId: floorLayoutId,
    staffId: '',
    storeId: storeId,
    numPeople: 4,
  };

  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(BOOK_DINE_IN_TABLE_API, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const { data: { dineInOccupancy = {} } = {} } = await response.json();
  return dineInOccupancy;
}

const getPaymentStatusDisplay = (orderObj3d, prevOrder) => {
  const currentOrder = orderObj3d?.order;
  const prevPayments = prevOrder?.payments;
  if (currentOrder?.paymentType === ORDER_PAYMENT_TYPE.unpaid) {
    return prevPayments?.length <= 0 ? 'unpaid' : 'partially paid';
  } else if (currentOrder?.paymentType === ORDER_PAYMENT_TYPE.card_online) {
    if (prevPayments?.length >= 1) {
      const prevPayableAmount = Number(prevOrder?.payableAmount || 0);
      const prevTotalPayments = prevPayments?.reduce((total, obj) => {
        return (total = total + Number(obj.amount));
      }, 0);

      if (prevPayableAmount > prevTotalPayments) {
        return 'partially paid';
      }
    } else {
      const prevPayableAmount = Number(prevOrder?.payableAmount || 0);
      if (prevPayableAmount > 0) {
        return 'partially paid';
      }
    }
  }
  return 'paid';
};

export function editDineinOrderObject(prevOrder, orderObj3d) {
  const totalCost = (
    Number(prevOrder?.totalCost || 0) + Number(orderObj3d.order.totalCost)
  ).toFixed(2);
  const payableAmount = (
    Number(prevOrder?.payableAmount || 0) +
    Number(orderObj3d.order.payableAmount)
  ).toFixed(2);
  const newPayableAmount = Number(orderObj3d.order.payableAmount).toFixed(2);

  const iteration = Number(prevOrder?.iteration || 0) + 1;
  const date = prevOrder?.date || orderObj3d?.date;
  const deliveryCode = prevOrder?.deliveryCode;

  const confirmedAt = new Date().getTime();

  const paymentStatusDisplay = getPaymentStatusDisplay(orderObj3d, prevOrder);

  const prevOrderIndex =
    prevOrder?.menuItems?.length > 0
      ? Math.max(
          ...prevOrder.menuItems.map((pMenuItem) => pMenuItem?.orderIndex || 0)
        ) || 0
      : 0;
  let newOrderIndex = prevOrderIndex + 1;

  const isHalfPresentInPreviousOrder = prevOrder?.menuItems?.some(
    (pMenuItem) => pMenuItem?.isHalf
  );
  const prevHalfIndex =
    isHalfPresentInPreviousOrder || prevOrder?.menuItems?.length > 0
      ? Math.max(
          ...prevOrder.menuItems.map((pMenuItem) => pMenuItem?.halfIndex || 0)
        ) || 0
      : 0;
  let newHalfIndex = prevHalfIndex + 1;
  const newHalfIndexes = {};
  if (isHalfPresentInPreviousOrder) {
    orderObj3d?.order?.menuItems?.forEach((mItem) => {
      if (mItem?.isHalf && !newHalfIndexes[mItem?.halfIndex]) {
        newHalfIndexes[mItem?.halfIndex] = newHalfIndex++;
      }
    });
  }

  const prevIteration = iteration - 1;
  const isEditOrder = prevOrder ? true : undefined;
  const _id = prevOrder?._id;

  // first we map through the new menu and increment it's iteration and then we concat it with prev menu
  const menuItems = [...(prevOrder?.menuItems || [])].concat(
    (orderObj3d?.order?.menuItems || []).map((item) => {
      if (item?.isHalf && isHalfPresentInPreviousOrder) {
        return {
          ...item,
          halfIndex: newHalfIndexes[item?.halfIndex],
          iteration,
          orderIndex: newOrderIndex++,
        };
      }
      return {
        ...item,
        iteration,
        orderIndex: newOrderIndex++,
      };
    })
  );

  const newOrderObj3d = {
    ...orderObj3d,
    order: {
      ...prevOrder,
      ...orderObj3d.order,
      date,
      deliveryCode,
      iteration,
      menuItems,
      totalCost,
      payableAmount,
      confirmedAt,
      newPayableAmount,
      paymentStatusDisplay,
      prevIteration,
      isEditOrder,
      _id,
    },
  };

  return newOrderObj3d;
}

export function setDineinOrderObject(orderObj3d, dineInOccupancy, iteration) {
  const occupancyId = dineInOccupancy?.id;

  // first we map through the new menu and increment it's iteration and then we concat it with prev menu
  const menuItems = orderObj3d?.order?.menuItems?.map((item) => ({
    ...item,
    iteration,
  }));
  const newOrderObj3d = {
    ...orderObj3d,
    order: {
      ...orderObj3d.order,
      iteration,
      menuItems,
      occupancyId,
    },
  };

  return newOrderObj3d;
}

export function setActiveSeatId(orderObj3d) {
  const menuItems = orderObj3d?.order?.menuItems?.map((item) => {
    if (!item?.activeSeatId) {
      return {
        ...item,
        activeSeatId: 'Shared',
      };
    }
    return item;
  });
  const newOrderObj3d = {
    ...orderObj3d,
    order: {
      ...orderObj3d.order,
      menuItems,
    },
  };

  return newOrderObj3d;
}
